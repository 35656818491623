import { AntboxError, Either, Node, NodeServiceClient, left, right, NodeFilter } from "../deps";
import type { EventFormat, Event, PaginationOptions } from "../models";

interface EventsWithPage {
    events: Event[];
    pageCount: number;
}

export class EventService {
    #EVENT_UUID = "event";
    #EVENT_ONLINE_UUID = "online";
    #EVENT_IN_PERSON_UUID = "in-person";

    constructor(private readonly nodeServiceClient: NodeServiceClient, private readonly tenant: string) {}

    async get(eventUuid: string): Promise<Either<AntboxError, Event>> {
        const eventOrErr = await this.nodeServiceClient.query([["uuid", "==", eventUuid]]);

        if (eventOrErr.isRight()) {
            return right(this.#convertNodeToEventModel(eventOrErr.value.nodes[0]));
        }

        return left(eventOrErr.value);
    }

    async list(options?: PaginationOptions): Promise<Either<AntboxError, EventsWithPage>> {
        const events: Event[] = [];
        const perPage = options?.perPage ?? 6
        const page = options?.page ?? 1
        const query: NodeFilter[] = [["aspects", "contains", this.#EVENT_UUID]]
        const eventsOrErr = await this.nodeServiceClient.query(query, perPage, page);        

        if (eventsOrErr.isLeft()) {
            return left(eventsOrErr.value);
        }

        eventsOrErr.value.nodes.forEach(async (n) => {
            events.push(this.#convertNodeToEventModel(n));
        });

        return right({events, pageCount: eventsOrErr.value.pageCount });
    }

    #convertNodeToEventModel(node: Node): Event {
        return {
            id: node.uuid,
            title: node.title,
            description: node.properties?.[`${this.#EVENT_UUID}:description`] as string,
            date: new Date(node.properties?.[`${this.#EVENT_UUID}:date`] as string),
            format: node.properties?.[`${this.#EVENT_UUID}:format`] as EventFormat,
            plataform: node.properties?.[`${this.#EVENT_ONLINE_UUID}:platform`] as string,
            link: node.properties?.[`${this.#EVENT_ONLINE_UUID}:link`] as string,
            address: node.properties?.[`${this.#EVENT_IN_PERSON_UUID}:location`] as string,
            imgUrl: this.#getImageUrl(node.uuid)
        };
    }

    #getImageUrl (uuid: string) {
        const url = this.nodeServiceClient.getNodeUrl(uuid)
        return `${url}?x-tenant=${this.tenant}`
    }
}
