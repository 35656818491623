<script setup lang="ts">
import type { Event } from "~/lib/models";
import { formatDate } from "~/lib/helpers/formatdate";

interface Props {
    event: Event;
}

defineProps<Props>();

async function navigateToEventDetails (eventId: string)  {
    await navigateTo(`/events/${eventId}`)
} 
</script>

<template>
    <div
        class="flex w-full md:flex-col gap-4 px-4 py-2 md:p-0 md:w-[18rem] cursor-pointer bg-grey-100 shadow-lg shadow-black/5 hover:shadow-2xl transition-all"
        @click="navigateToEventDetails(event.id)"
    >
        <div class="w-36 h-36 md:w-full md:h-auto">
            <img :src="event.imgUrl" class="h-full w-full md:aspect-[637/863]" />
        </div>

        <div class="flex flex-col gap-2 md:gap-4 flex-1 md:relative md:p-4">
            <span class="text-blue-900 text-xs md:mt-1">{{ formatDate(event.date) }}</span>

            <h2 class="font-bold text-primary text-base md:text-lg md:text-center">{{ event.title }}</h2>

            <span class="bg-primary text-white text-sm uppercase p-1 w-fit md:absolute top-0 md:top-4 right-0 md:right-4">{{
                event.format
            }}</span>

            <div class="hidden md:block text-sm">
                <p class="h-9 overflow-hidden" v-html="event.description"></p>
                <span>...</span>
            </div>
            <div class="hidden md:flex flex-1 justify-end items-end">
                <span
                    class="cursor-pointer underline text-primary"
                    >Saber mais...</span
                >
            </div>
        </div>
    </div>
</template>